

import { Vue, Component, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { Communicate } from '../../types/supplier'
import { FileInfo } from '../../types/common'
import { CommunicateTypeName } from '@/utils/enum'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { UploadFile }
})
export default class SupplierCommunicateAdd extends Vue {
  @Ref('form') readonly form!: ElForm

  token = localStorage.getItem('token')
  communicateTypeName = CommunicateTypeName

  communicateForm: Communicate = {
    communicateId: '',
    communicateTime: '',
    contactUserId: '',
    communicateType: '',
    communicateSubject: '',
    communicateContent: '',
    supplierId: '',
    resourceList: []
  }

  rules = {
    communicateTime: [{ required: true, message: '请选择沟通时间', trigger: 'change' }],
    contactUserId: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
    communicateType: [{ required: true, message: '请选择联系方式', trigger: 'change' }],
    communicateContent: [{ required: true, message: '请输入沟通内容', trigger: 'blur' }],
    resourceList: [{ required: true, type: 'array', message: '请上传沟通资料', trigger: 'change' }]
  }

  created () {
    this.communicateForm.supplierId = this.$route.params.id
    this.communicateForm.communicateId = this.$route.query.communicateId as string
    if (this.communicateForm.communicateId) {
      this.loadData()
    }
  }

  loadData () {
    this.$axios.get<Communicate>(this.$apis.supplier.getCommunicationById, {
      communicateId: this.communicateForm.communicateId
    }).then(res => {
      this.communicateForm = res;
      (this.$refs.uploadFile as UploadFile).getUpdateFileInfo(res.resourceList)
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        const url = this.communicateForm.communicateId ? this.$apis.supplier.updateCommunication : this.$apis.supplier.insertCommunication
        this.$axios.post(url, this.communicateForm).then(() => {
          this.$router.back()
        })
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.communicateForm.resourceList.push(file)
  }

  // 文件删除
  onRemove (key: string) {
    const num = this.communicateForm.resourceList.findIndex((item: FileInfo) => {
      return item.fileUrl === key
    })
    this.communicateForm.resourceList.splice(num, 1)
  }
}
